<template>
    <NuxtLayout>
        <NuxtPage />
    </NuxtLayout>
</template>

<script setup>
import { useCountriesStore } from '../../libs/shared/store/countries.js';

const countriesStore = useCountriesStore();
const { getCountries } = countriesStore;

onMounted(async () => {
    await nextTick();
    getCountries();
});
</script>

<script>
export default {
    async mounted() {
        this.$primevue.config.ripple = true;
        if (useCookie('token').value) {
            await this.$store.dispatch('countries/getCountriesRegistration');
        }
    },
};
</script>

<script setup></script>
